import { motion } from 'framer-motion'
import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import FeaturedEventBanner from 'components/FeaturedEvents/FeaturedEventBanner'
import { CenterContainer } from 'components/common/CenterContainer'
import { NextSeo } from 'next-seo'
import GenericLayout from 'components/GenericLayout'
import { Button, Typography } from 'components/common'
import { twMerge } from 'tailwind-merge'
import ModalTrigger from 'components/common/ModalTrigger'
import { useBreakpoint } from 'hooks/useBreakpoint'
import BaseModal from 'components/modals/BaseModal'
import MFDAnnouncementBanner from 'components/Collectives/Common/MFDAnnouncementBanner'

const metaImage =
  'https://i.upstreamapp.com/ogImages/homepage/home-opengraph-2.png'

const metaUrl = 'https://upstreamapp.com'

const analyticsPreFix = 'Homepage:'

function Index() {
  const { isMobile } = useBreakpoint()

  return (
    <>
      <NextSeo
        openGraph={{
          url: metaUrl,
          images: [
            {
              url: metaImage,
              width: 1200,
              height: 630
            }
          ]
        }}
        twitter={{
          cardType: 'summary_large_image'
        }}
        additionalMetaTags={[
          {
            name: 'twitter:image',
            content: metaImage
          },
          {
            name: 'twitter:image:alt',
            content: 'DAOs Introduction Flyer'
          }
        ]}
      />

      <MFDAnnouncementBanner />

      <GenericLayout bgColor="bg-white" fullWidth>
        <FeaturedEventBanner />

        <main>
          <CenterContainer className="gap-0 -mt-8">
            <div className="flex justify-center w-full">
              <div className="mx-8 flex sm:w-4/5 justify-between flex-col sm:flex-row">
                <div className="flex flex-col">
                  <div className="mt-6 mb-6">
                    <div className="hidden sm:block">
                      <p className="text-text-primary text-7xl font-black tracking-tight py-1.5">
                        The <span className="capitalize">easiest</span>
                      </p>
                      <p className="text-text-primary text-7xl font-black tracking-tight py-1.5">
                        <span className="capitalize">way</span> to
                      </p>
                      <p className="text-text-primary text-7xl font-black tracking-tight py-1.5">
                        <span className="capitalize">start</span> a DAO
                      </p>
                    </div>
                    <div className="sm:hidden">
                      <p className="text-text-primary text-5xl font-black tracking-tight py-1.5">
                        The <span className="capitalize">easiest</span>
                      </p>
                      <p className="text-text-primary text-5xl font-black tracking-tight py-1.5">
                        <span className="capitalize">way</span> to
                      </p>
                      <p className="text-text-primary text-5xl font-black tracking-tight py-1.5">
                        <span className="capitalize">start</span> a DAO
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 mb-10">
                    <Typography
                      size="heading"
                      fontWeight="light"
                      className="max-w-[600px]"
                    >
                      Upstream makes it simple to launch a DAO with its no-code,
                      full-stack platform. No technical experience required.
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-center mb-20">
                  <Image
                    alt="Upstream robot animation"
                    src="/static/homepage/Robot Animation 2.svg"
                    height={614}
                    width={492}
                    priority
                  />
                </div>
              </div>
            </div>

            <div className="home-gradient-box-1 pt-8 w-full">
              <CenterContainer>
                <div className="flex items-center flex-col pt-8 px-8 max-w-[1024px]">
                  <div className="text-center">
                    <Typography
                      size={'heading-3xl'}
                      fontWeight="bold"
                      className="tracking-[-0.5px] leading-[1.2]"
                    >
                      Upstream DAOs
                    </Typography>
                  </div>

                  <div className="mt-6 max-w-[890px] text-center">
                    <Typography size="xl" fontWeight="light">
                      The simplest way to start and manage a DAO. Upstream is a
                      no-code, full-stack, DAO-in-a-box platform. Voting, chat,
                      events, proposals, governance, and your treasury all in
                      one place.
                    </Typography>
                  </div>

                  <div className="mt-4 mb-8">
                    <Link href="/collectives" passHref>
                      <Button
                        color="textOnly"
                        className="border-2 border-blue-600 px-16 text-blue-600 hover:bg-blue-100 hover:text-blue-600"
                        size="xl"
                        rounded="lg"
                        label="Launch Your Own DAO"
                        icon={faArrowRight}
                        iconPlacement="after"
                        onClick={() => {
                          window.analytics.track(
                            `${analyticsPreFix} Launch Your Own DAO`
                          )
                        }}
                      />
                    </Link>
                  </div>

                  <div className="relative">
                    <ModalTrigger
                      trigger={open => {
                        return (
                          <div
                            className={twMerge(
                              isMobile ? '-ml-4' : '-ml-10',
                              'group cursor-pointer'
                            )}
                            onClick={open}
                          >
                            <Image
                              width={1164}
                              height={447}
                              src="/static/homepage/CollectivesUI.png"
                              alt="Upstream DAO"
                            />
                            {/* BG Hover affect */}
                            <div className="absolute top-1/4 left-[40%] max-sm:w-[75px] w-[135px] max-sm:h-[75px] h-[135px] bg-gray-500 opacity-0 group-hover:opacity-50 rounded-full" />
                            <div className="absolute top-1/4 left-[40%] max-sm:w-[75px] w-[135px] max-sm:h-[75px] h-[135px]">
                              <Image
                                src="/static/homepage/Play.png"
                                width={isMobile ? 75 : 135}
                                height={isMobile ? 75 : 135}
                                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                alt="What is Upstream DAO Video Play Button"
                              />
                            </div>
                          </div>
                        )
                      }}
                    >
                      <BaseModal
                        classNames={
                          isMobile
                            ? 'min-w-[95%] max-h-[420px]'
                            : 'w-[795px] min-w-[795px] sm:max-w-full'
                        }
                      >
                        <iframe
                          id="video-frame"
                          width={isMobile ? '100%' : '795'}
                          height={isMobile ? '100%' : '447'}
                          src="https://www.youtube-nocookie.com/embed/0yRhtscWmaM?autoplay=1"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </BaseModal>
                    </ModalTrigger>
                  </div>
                </div>
              </CenterContainer>
            </div>

            <div className="home-gradient-box-2 pt-8 pb-12 w-full">
              <CenterContainer>
                <div className="flex w-full max-w-[1024px] flex-col justify-center p-4 sm:p-10 lg:p=0">
                  <div
                    className={twMerge(
                      'pt-4 pb-8 flex ',
                      isMobile ? 'justify-center' : ''
                    )}
                  >
                    <Typography
                      color="white"
                      fontWeight="light"
                      size="heading"
                      component={'h5'}
                    >
                      Learn More about DAOs
                    </Typography>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-4 lg:gap-16">
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <div className="bg-[#EEF4FE] shadow-xl rounded-xl overflow-hidden h-full">
                        <a
                          href="https://youtu.be/ar_UlNIqfQw"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            window.analytics.track(
                              `${analyticsPreFix} DAOs: What is a DAO and how does it work`
                            )
                          }}
                        >
                          <div className="p-4">
                            <div
                              className={twMerge(
                                'relative',
                                isMobile ? 'w-full h-[200px]' : ''
                              )}
                            >
                              <Image
                                src="/static/homepage/whatisdao.png"
                                width={isMobile ? undefined : 400}
                                height={isMobile ? undefined : 219}
                                fill={isMobile}
                                alt="Upstream DAO"
                              />
                            </div>
                            <div className="mt-2 max-w-[320px]">
                              <Typography>
                                What is a DAO and how does it work?
                              </Typography>
                            </div>
                          </div>
                        </a>
                      </div>
                    </motion.div>

                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <div className="bg-[#EEF4FE] shadow-xl rounded-xl overflow-hidden h-full">
                        <a
                          href="https://guide.upstreamapp.com/dao-members/joining-a-dao"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            window.analytics.track(
                              `${analyticsPreFix}: DAOs: How Do I Join a DAO`
                            )
                          }}
                        >
                          <div className="p-4">
                            <div
                              className={twMerge(
                                'relative',
                                isMobile ? 'w-full h-[200px]' : ''
                              )}
                            >
                              <Image
                                src="/static/homepage/howtojoindao.png"
                                width={isMobile ? undefined : 400}
                                height={isMobile ? undefined : 219}
                                fill={isMobile}
                                alt="How to Join Upstream DAO"
                              />
                            </div>
                            <div className="mt-2 max-w-[320px]">
                              <Typography>
                                How do I join a DAO?
                                <br />
                              </Typography>
                            </div>
                          </div>
                        </a>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </CenterContainer>
            </div>

            <div
              className={twMerge(
                'flex gap-4 mt-16 mb-20 max-w-[1024px]',
                isMobile ? 'flex-col max-4' : 'mx-24'
              )}
            >
              <div
                className={twMerge(
                  'sticky top-0 p-8 z-[1000] sm:h-[280px] bg-white',
                  isMobile ? 'pt-4' : 'pt-16'
                )}
              >
                <Typography
                  className={'leading-[1.1] tracking-[-0.5px]'}
                  size={isMobile ? 'heading-2xl' : 'heading-3xl'}
                  fontWeight="bold"
                  component="h5"
                >
                  Complete Suite of DAO Tools
                </Typography>

                <div
                  className={twMerge(
                    'mt-8 sm:mb-8',
                    isMobile ? 'min-w-[300px]' : 'min-w-[400px]',
                    'max-w-full md:max-w-[500px]'
                  )}
                >
                  <div className="hidden sm:block">
                    <Typography size="heading" fontWeight="light">
                      Upstream has everything you need to host your DAO on the
                      blockchain.
                    </Typography>
                  </div>
                  <div className="sm:hidden">
                    <Typography size="lg" fontWeight="light">
                      Upstream has everything you need to host your DAO on the
                      blockchain.
                    </Typography>
                  </div>
                </div>

                <div className="hidden sm:block">
                  <Link href="/collectives" passHref>
                    <Button
                      color="textOnly"
                      className="border-2 border-blue-600 px-16 text-blue-600 hover:bg-blue-100 hover:text-blue-600"
                      size="xl"
                      rounded="lg"
                      label="Discover Features"
                      icon={faArrowRight}
                      iconPlacement="after"
                    />
                  </Link>
                </div>
              </div>

              <div className="flex flex-col gap-8">
                <div className="p-8 bg-[#EEF4FE] flex flex-col items-center">
                  <Image
                    src="/static/homepage/Events.svg"
                    width={300}
                    height={300}
                    alt="Upstream Event"
                  />

                  <div className="w-full pt-8">
                    <Typography
                      fontWeight="bold"
                      className={'text-[36px] tracking-[-0.5px]'}
                      component="h6"
                    >
                      Community Wallet
                    </Typography>
                  </div>

                  <Typography size="lg" fontWeight="light">
                    Bring together money, NFTs, and other web3 tokens in a
                    shared wallet. Contribute to the community’s wallet and vote
                    on proposals to decide what actions to take as a community.
                  </Typography>
                </div>
                <div className="p-8 bg-[#EEF4FE] flex flex-col items-center">
                  <Image
                    alt="Office hours"
                    src="/static/homepage/Office Hours.svg"
                    width={300}
                    height={300}
                  />

                  <div className="w-full pt-8">
                    <Typography
                      fontWeight="bold"
                      className={'text-[36px] tracking-[-0.5px]'}
                      component="h6"
                    >
                      Voting & Proposals
                    </Typography>
                  </div>

                  <Typography size="lg" fontWeight="light">
                    Every DAO member can create, discuss, and vote on proposals.
                    Proposals need a majority vote to pass and quorum to be
                    reached for the decision to be executed.
                  </Typography>
                </div>

                <div className="md:hidden flex justify-center">
                  <Link href="/collectives" passHref>
                    <Button
                      color="textOnly"
                      className="border-2 border-blue-600 px-20 text-blue-600 hover:bg-blue-100 hover:text-blue-600"
                      size="xl"
                      rounded="lg"
                      label="Discover Features"
                      icon={faArrowRight}
                      iconPlacement="after"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </CenterContainer>
        </main>
      </GenericLayout>
    </>
  )
}

export default Index
